<script setup lang="ts">
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'

  const props = defineProps<{
    id: string
    quote: QuotedPlanVm
  }>()

  const { getId } = Id.use(props.id)

  const { getComponentContent } = Cms.use()

  const { content } = await getComponentContent('QuoteCardSectionPlanScoreCallout')
</script>

<template>
  <section class="flex grow flex-col items-center justify-center rounded-3xl bg-sky-100 py-3">
    <HpIcon name="healthpilot-icon-simple" class="mb-2 scale-125" />
    <h2 class="mb-1 text-xl font-semibold">
      {{ content.ext.title }}
    </h2>
    <QuotePlanScoreV2 :score="quote.score" band-thickness="thin" size="large" class="mb-2" />
    <div class="w-full max-w-sm px-2 text-center leading-tight text-gray-700">
      {{ content.ext.description }}
    </div>
    <QuoteCardScoreSidebar
      :id="getId(`score-calc-sidebar`)"
      :quote="quote"
      :label="content.ext.sidebar_link"
    />
  </section>
</template>

<style scoped>
  :deep(.score-value.large) {
    @apply text-5xl;
  }
</style>
