<script setup lang="ts">
  import { PlanType, PolicyType, SnpType } from '~/generated/api-clients-generated'
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import type { PlanBannerConfig } from '~/composables/PlanBanners'
  import QuoteCardPolicyBadges from '~/components/quote/card/QuoteCardPolicyBadges.vue'

  const props = defineProps<{
    id: string
    quote: QuotedPlanVm
    banner?: Partial<PlanBannerConfig & { text: string }> | null | undefined
  }>()

  const { show } = DialogController.use()
  const { getId } = Id.use(props.id)
  const { flag } = FeatureFlags.use()

  const isMapd = computed(() => props.quote.type === PlanType.MAPD)
  const isDsnp = computed(() => props.quote.snpType === SnpType.DualEligible)

  const { getComponentContent, getTooltipContent, hydratePlaceholders } = Cms.use()

  const { content } = await getComponentContent('QuoteCard')

  const { content: starRatingTooltip } = await getTooltipContent('MedicareStarRating')

  const showPlanFitModal = computed(() => flag<boolean>('plan-fit-modal').value)

  const { banner } = await PlanBanners.use(props.quote.medicareId)

  const usedBanner = computed(() => props.banner ?? banner.value)

  const { devModeEnabled, openInConnecture, isHeatEnabled } = DevModeHelpers.use()
</script>

<template>
  <section
    :class="['header-banner', `${usedBanner?.color}`]"
    v-if="!!usedBanner || !!$slots.banner"
  >
    <div class="banner-content">
      <slot name="banner">
        <HpIcon v-if="!!usedBanner?.icon" :name="usedBanner.icon" class="-mt-1" />
        {{ usedBanner?.text }}
      </slot>
    </div>
  </section>
  <section class="header-plan-info">
    <div class="flex h-20 w-full items-center justify-center">
      <PxCarrierLogo
        :class="['centered-carrier-logo', `${quote.details.carrierKey}`]"
        :logo-key="quote.details.logoKey!"
        :plan-year="quote.details.year!"
        :logo-alt="`${quote.details.logoKey} carrier logo`"
      />
    </div>
    <div class="flex w-full flex-col gap-y-2">
      <div class="flex w-full justify-center">
        <h2 class="text-center text-xl font-semibold">
          {{ quote.name }}
        </h2>
      </div>
      <div class="flex flex-col items-center justify-center gap-x-2 sm:flex-row">
        <div class="flex w-full justify-center gap-x-2 sm:contents">
          <QuoteBadge :text="quote.details.year!.toString()" variant="white" />
          <QuoteCardPolicyBadges :id="getId()" :quote="quote" />
        </div>
        <PxStarRating
          :star-rating="quote.details.starRating"
          :tooltip="{
            id: getId(`star-rating-tooltip`),
            title: starRatingTooltip.title,
            body: hydratePlaceholders(starRatingTooltip.body, { planYear: quote.details.year })
          }"
        />
      </div>
    </div>
  </section>

  <template v-if="devModeEnabled && isHeatEnabled(quote)">
    <div class="mb-5 flex w-full items-center justify-center gap-2">
      <HpIcon name="login" class="w-4 text-primary" />
      <HpAnchor class="text-sm font-bold" @click.prevent="openInConnecture(quote.externalId)"
        >Open app in Connecture
      </HpAnchor>
    </div>
  </template>
</template>

<style scoped>
  .header-plan-info {
    @apply flex w-full flex-col gap-y-2 px-2 py-4;

    :deep(.centered-carrier-logo) {
      @apply object-fill object-center;
    }

    .centered-carrier-logo.highmark {
      @apply h-full;
    }
  }

  .header-banner {
    @apply flex h-[2.6rem] w-full items-center justify-center rounded-tl-quote rounded-tr-quote bg-sky-300;

    .banner-content {
      @apply flex items-center gap-x-2 text-lg font-bold;

      :deep(svg) {
        @apply text-blue-500;
      }
    }

    &.green {
      @apply bg-success-light;

      .banner-content {
        :deep(svg) {
          @apply !text-success-dark;
        }
      }
    }
  }
</style>
