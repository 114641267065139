<script setup lang="ts">
  import { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import type { PlanBannerConfig } from '~/composables/PlanBanners'
  import QuoteCardHeader from '~/components/quote/card/QuoteCardHeader.vue'
  import QuoteCardFooter from '~/components/quote/card/QuoteCardFooter.vue'

  const props = defineProps<{
    quote: QuotedPlanVm
    id?: string
    banner?: Partial<PlanBannerConfig & { text: string }> | null | undefined
  }>()

  const { getId } = Id.use(props.id ?? props.quote.medicareId)
</script>

<template>
  <QuoteCardBase :id="getId()" :quote="quote" class="highlighting-plan-fit">
    <template #header>
      <QuoteCardHeaderCentered :quote="quote" :banner="banner" :id="getId('header')" />
    </template>
    <template #default="{ showPlanTags, isMapd }">
      <QuoteCardSectionPlanScoreCallout :id="getId('plan-score-callout')" :quote="quote" />
      <QuoteCardSectionPlanTagsBoxed
        :quote="quote"
        :id="getId(`plan-tags-section`)"
        v-if="showPlanTags"
      />
      <QuoteCardSectionCostsPreview
        :quote="quote"
        :id="getId('costs-preview-section')"
        hide-header
      />
      <QuoteCardSectionBenefitButtons
        :quote="quote"
        v-if="isMapd"
        :id="getId('benefitButtons')"
        hide-header
      />
      <QuoteCardSectionActions :quote="quote" />
    </template>
    <template #footer>
      <QuoteCardFooter :quote="quote" />
    </template>
  </QuoteCardBase>
</template>

<style></style>
